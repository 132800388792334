/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React from 'react';
import {useSelector} from 'react-redux';

import mothership from '../../../src/assets/images/mothership.svg';
import {selectSelectedRegion} from '../../state/app/appSlice';


/**
 * Home component.
 * @class for dashboard.
 */
export const Home = () => {
        let apiDocLink;
        const selectedRegion = useSelector(selectSelectedRegion);
        if (window.location.hostname.includes('prod')) {
            if (selectedRegion.toString().includes('NA')) {
                apiDocLink = 'https://console.harmony.a2z.com/swagger-ui/?roleArn=arn:aws:iam::288376980878:role/SwaggerUI&s3Bucket=id4mothership-database-st-id4motherships3swaggerbu-ja5rpbzb50mp&s3Key=ID4MothershipModel-1.0.swagger.api.json&region=us-east-1';
            } else if (selectedRegion.toString().includes('EU')) {
                apiDocLink = 'https://console.harmony.a2z.com/swagger-ui/?roleArn=arn:aws:iam::531602046189:role/SwaggerUI&s3Bucket=id4mothership-database-st-id4motherships3swaggerbu-a1d1ljzxktrg&s3Key=ID4MothershipModel-1.0.swagger.api.json&region=eu-west-1';
            } else {
                apiDocLink = 'https://console.harmony.a2z.com/swagger-ui/?roleArn=arn:aws:iam::417195482276:role/SwaggerUI&s3Bucket=id4mothership-database-st-id4motherships3swaggerbu-jbcmvqhcjc6d&s3Key=ID4MothershipModel-1.0.swagger.api.json&region=ap-northeast-1'
            }
        } else if (window.location.hostname.includes('gamma')) {
            apiDocLink = 'https://console.harmony.a2z.com/swagger-ui/?roleArn=arn:aws:iam::253284696335:role/SwaggerUI&s3Bucket=id4mothership-database-st-id4motherships3swaggerbu-t0f4ueb1r1xa&s3Key=ID4MothershipModel-1.0.swagger.api.json&region=us-east-1';
        } else if (window.location.hostname.includes('beta')){
            apiDocLink = 'https://console.harmony.a2z.com/swagger-ui/?roleArn=arn:aws:iam::797866347963:role/SwaggerUI&s3Bucket=id4mothership-database-st-id4motherships3swaggerbu-imel5akoldqi&s3Key=ID4MothershipModel-1.0.swagger.api.json&region=us-east-1';
        } else {
            apiDocLink = 'https://console.harmony.a2z.com/swagger-ui/?roleArn=arn:aws:iam::255858962248:role/SwaggerUI&s3Bucket=id4mothership-database-st-id4motherships3swaggerbu-pjucajwsppw5&s3Key=ID4MothershipModel-1.0.swagger.api.json&region=us-east-1';
        }
        return (
        <Row
            spacing='500'
            alignmentHorizontal='center'
            widths={[530, 'fill']}
            height={'60vh'}
            wrap='down'
            spacingInset='600 600 none 600'
        >
            <img src={mothership} alt='Image of Mothership'/>
            <Column spacing='500' maxWidth={450}>
                <Heading type='d100' level={1}>
                        Centralizing problems in Amazon Robotics
                </Heading>
                <Text>
                        ID4 is a scalable platform for detecting/reporting problems and performing/publishing diagnostics in the
                        Amazon Robotics and Advanced Technologies ecosystem
                </Text>
                <Row>
                    <Button href='/problemFinders/create'>
                            Onboarding
                    </Button>
                    <Button type={'secondary'}
                        href='https://w.amazon.com/bin/view/Amazon-Robotics/GSS/TOOLS/ID4/Integration/'>
                            Integrating
                    </Button>
                    <Button  type={'secondary'}
                        href='/dashboard'>
                            Monitoring
                    </Button>
                    <Button  type={'secondary'}
                             href={apiDocLink}>
                        API Documentation
                    </Button>

                </Row>
            </Column>
        </Row>
    );
}
;

export default Home;
