/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {ImpactLevel, Problem, ProblemStatus} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problem/types';
import {Metric, Responsive, Tab, TabGroup, Text, Tile,} from '@amzn/meridian';
import Alert from '@amzn/meridian/alert';
import {AlertProps} from '@amzn/meridian/alert/alert';
import Box from '@amzn/meridian/box';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Legend, {LegendProvider} from '@amzn/meridian/legend';
import victoryLinePlugin from '@amzn/meridian/legend/plugin/victory-line';
import victoryTooltipPlugin from '@amzn/meridian/legend/plugin/victory-tooltip';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Select, {SelectOption} from '@amzn/meridian/select';
import useVictoryTheme from '@amzn/meridian/use-victory-theme';
import {useBundle} from '@amzn/react-arb-tools';
import {capitalize} from 'lodash';
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {VictoryAxis, VictoryChart, VictoryLine, VictoryVoronoiContainer,} from 'victory';

import {AppContext} from '../../app';
import {selectSelectedRegion} from '../../state/app/appSlice';
import {listProblems, listProblemTypeDefinitions} from '../../utility/id4-mothership-client';
import PageHeader from '../utility-views/PageHeader';
import {
    formatHour,
    formatToMonthDayPadded,
    generateMutedColorFromString,
    getImpactColor,
    getProblemStatusColor,
    impactLevelComparator
} from './GraphUtility';


/**
 * Datapoint interface.
 */
interface DataPoint {
    key: string;
    value: number;
}

/**
 * Dataset interface.
 */
interface Dataset {
    key: string;
    data: DataPoint[];
    label: string;
    color?: string;
}


/**
 * Problem dataset enum.
 */
enum ProblemDataset {
    BY_PROBLEM_TYPE = 'By Problem Type',
    BY_IMPACT_LEVEL = 'By Impact Level',
    BY_PROBLEM_STATUS = 'By Problem Status',
}


/**
 * Tab options.
 */
enum TabOptions {
    SUMMARY = 'SUMMARY',
    LIVE = 'LIVE'
}

/**
 * Ms per hours.
 */
const MS_PER_HOUR = 3600000;


/**
 * Prod lookback period.
 */
const LOOKBACK = 6 * MS_PER_HOUR;


/**
 * Dashboard component.
 * @class for dashboard.
 */
export const Dashboard = () => {

    const [commonBundle] = useBundle('common.common');
    const MAX_WIDTH = 1800;
    const [alertMessage, setAlertMessage] = useState<{
        type: AlertProps['type'],
        title?: AlertProps['title'],
        message: string
    }>(undefined);
    const [loadingResources, setLoadingResources] = useState<boolean>(true);
    const {webStageConfig} = React.useContext(AppContext);
    const selectedRegion = useSelector(selectSelectedRegion);
    const [allOpenProblems, setAllOpenProblems] = useState<Problem[]>([]);
    const [recentOpenProblems, setRecentlyOpenProblems] = useState<Problem[]>([]);
    const [recentClosedProblems, setRecentlyClosedProblems] = useState<Problem[]>([]);
    const [recentExpiredProblems, setRecentlyExpiredProblems] = useState<Problem[]>([]);
    const [filteredProblems, setFilteredProblems] = useState<Problem[]>([]);
    const [problemTypes, setProblemTypes] = useState<string[]>([]);
    const [lookbackPeriod, setLookbackPeriod] = useState<number>(LOOKBACK);
    const [selectedProblemType, setSelectedProblemType] = useState<string>('ALL');
    const [selectedDataset, setSelectedDataset] = useState<ProblemDataset>(ProblemDataset.BY_PROBLEM_TYPE);
    const theme = useVictoryTheme({showIndependentGrid: true});
    const [currentTab, setCurrentTab] = useState<TabOptions>(TabOptions.LIVE);
    const lineGraphDataSets: Dataset[] = useMemo(() => getDataset(filteredProblems), [filteredProblems, selectedDataset]);


    /**
     * Effect which gets a list of report configurations.
     */
    React.useEffect(() => {
        if (commonBundle !== undefined) {
            const fetchResources = async () => {
                const currentTimeMs = Date.now();

                const lookback = currentTimeMs - lookbackPeriod;
                setLoadingResources(true);

                const [problemTypes, openProblems, closedProblems, expiredProblems] = await Promise.all([
                    listProblemTypeDefinitions(webStageConfig, selectedRegion),
                    listProblems(webStageConfig, selectedRegion,
                        {
                            problemStatus: ProblemStatus.OPEN
                        }
                    ),
                    listProblems(webStageConfig, selectedRegion,
                        {
                            problemStatus: ProblemStatus.CLOSED,
                            identificationTimeEpochMs: lookback,
                            identificationEndTimeEpochMs: currentTimeMs
                        }
                    ),
                    listProblems(webStageConfig, selectedRegion,
                        {
                            problemStatus: ProblemStatus.EXPIRED,
                            identificationTimeEpochMs: lookback,
                            identificationEndTimeEpochMs: currentTimeMs
                        }
                    )
                ]);
                setAllOpenProblems(openProblems);
                setRecentlyOpenProblems(openProblems.filter(p => p.identificationTimeEpochMs >= lookback));
                setProblemTypes(problemTypes.map(pt => pt.problemTypeId).sort());
                setRecentlyClosedProblems(closedProblems);
                setRecentlyExpiredProblems(expiredProblems);
            };

            fetchResources()
                .catch(err => {
                    setAlertMessage({
                        type: 'error',
                        message: err.message,
                        title: 'Error loading known resources.'
                    });
                    console.error(err);
                })
                .finally(() => setLoadingResources(false));
        }
    }, [commonBundle, selectedRegion, lookbackPeriod]);


    /**
     * Effect which gets a list of report configurations.
     */
    React.useEffect(() => {
        setFilteredProblems([...recentOpenProblems, ...recentClosedProblems, ...recentExpiredProblems]
            .filter(p => selectedProblemType === p.problemType || selectedProblemType === 'ALL'));
    }, [recentOpenProblems, recentClosedProblems, recentExpiredProblems, selectedProblemType]);


    /**
     * Converts the problems into multiple data sets grouped by problem type and date.
     * If we have over 20 problem types, we group the bottom into an 'other' category.
     * @param problems to convert.
     * @returns {Dataset[]} the datasets.
     */
    function generateLineGraphDatasetsByType(problems: Problem[]): Dataset[] {
        const groupedProblems = new Map<string, Map<number, number>>();
        const totalsByType = new Map<string, number>();
        const allHours = new Set<number>();

        problems.forEach(problem => {
            const date = new Date(problem.creationTimeEpochMs);
            const hour = date.getHours();
            allHours.add(hour);

            if (!groupedProblems.has(problem.problemType)) {
                groupedProblems.set(problem.problemType, new Map());
            }
            const typeMap = groupedProblems.get(problem.problemType)!;
            typeMap.set(hour, (typeMap.get(hour) || 0) + 1);

            totalsByType.set(problem.problemType, (totalsByType.get(problem.problemType) || 0) + 1);
        });

        const sortedHours = Array.from(allHours).sort((a, b) => a - b);

        const sortedTypes = Array.from(totalsByType.entries())
            .sort((a, b) => b[1] - a[1])
            .map(([type]) => type);

        const datasets: Dataset[] = [];

        const topTypes = sortedTypes.slice(0, groupedProblems.size > 20 ? 19 : 20);

        topTypes.forEach(problemType => {
            const typeMap = groupedProblems.get(problemType)!;
            const dataset: Dataset = {
                key: problemType,
                label: problemType,
                color: generateMutedColorFromString(problemType),
                data: sortedHours.map(hour => ({
                    key: formatHour(hour),
                    value: typeMap.get(hour) || 0
                }))
            };
            datasets.push(dataset);
        });

        if (groupedProblems.size > 20) {
            const otherMap = new Map<number, number>();
            groupedProblems.forEach((typeMap, problemType) => {
                if (!topTypes.includes(problemType)) {
                    sortedHours.forEach(hour => {
                        const count = typeMap.get(hour) || 0;
                        otherMap.set(hour, (otherMap.get(hour) || 0) + count);
                    });
                }
            });

            const otherDataset: Dataset = {
                key: 'Other',
                label: 'Other',
                color: generateMutedColorFromString('Other'),
                data: sortedHours.map(hour => ({
                    key: formatHour(hour),
                    value: otherMap.get(hour) || 0
                }))
            };
            datasets.push(otherDataset);
        }

        datasets.sort((a, b) => a.label.localeCompare(b.label));
        return datasets;
    }

    /**
     * Converts the problems into multiple data sets grouped by impact level and date.
     * @param problems to convert.
     * @returns {Dataset[]} the datasets.
     */
    function generateLineGraphDatasetsByImpactLevel(problems: Problem[]): Dataset[] {
        const groupedProblems = new Map<string, Map<number, number>>();
        const allHours = new Set<number>();

        problems.forEach(problem => {
            const date = new Date(problem.creationTimeEpochMs);
            const hour = date.getHours();
            allHours.add(hour);

            if (!groupedProblems.has(problem.impactLevel)) {
                groupedProblems.set(problem.impactLevel, new Map());
            }
            const impactMap = groupedProblems.get(problem.impactLevel)!;
            impactMap.set(hour, (impactMap.get(hour) || 0) + 1);
        });

        const sortedHours = Array.from(allHours).sort((a, b) => a - b);

        const datasets: Dataset[] = [];

        groupedProblems.forEach((timeMap, impactLevel) => {
            const dataset: Dataset = {
                key: impactLevel,
                color: getImpactColor(impactLevel),
                label: capitalize(impactLevel),
                data: sortedHours.map(hour => ({
                    key: formatHour(hour),
                    value: timeMap.get(hour) || 0
                }))
            };
            datasets.push(dataset);
        });
        datasets.sort((a, b) => impactLevelComparator(a.label, b.label));
        return datasets;
    }

    /**
     * Converts the problems into multiple data sets grouped by problem status and date.
     * @param problems to convert.
     * @returns {Dataset[]} the datasets.
     */
    function generateLineGraphDatasetsByProblemStatus(problems: Problem[]): Dataset[] {
        const openByHour = new Map<number, number>();
        const expiredByHour = new Map<number, number>();
        const closedByHour = new Map<number, number>();
        const netOpenByHour = new Map<number, number>();

        const sortedProblems = [...problems].sort((a, b) =>
            a.identificationTimeEpochMs - b.identificationTimeEpochMs
        );

        const allHours = new Set<number>();

        sortedProblems.forEach(problem => {
            const openDate = new Date(problem.identificationTimeEpochMs);
            const openHour = openDate.getHours();
            openByHour.set(openHour, (openByHour.get(openHour) || 0) + 1);
            allHours.add(openHour);

            if (problem.closureTimeEpochMs) {
                const updateTime = new Date(problem.updateTimeEpochMs);
                const updateHour = updateTime.getHours();
                allHours.add(updateHour);

                if (problem.problemStatus === ProblemStatus.EXPIRED) {
                    expiredByHour.set(updateHour, (expiredByHour.get(updateHour) || 0) + 1);
                } else if (problem.problemStatus === ProblemStatus.CLOSED) {
                    closedByHour.set(updateHour, (closedByHour.get(updateHour) || 0) + 1);
                }
            }
        });

        const sortedHours = Array.from(allHours).sort((a, b) => a - b);

        let runningTotal = 0;
        sortedHours.forEach(hour => {
            runningTotal += (openByHour.get(hour) || 0);
            runningTotal -= (closedByHour.get(hour) || 0);
            runningTotal -= (expiredByHour.get(hour) || 0);
            netOpenByHour.set(hour, runningTotal);
        });

        return [
            {
                key: ProblemStatus.OPEN,
                label: 'Open',
                color: getProblemStatusColor(ProblemStatus.OPEN),
                data: sortedHours.map(hour => ({
                    key: formatHour(hour),
                    value: openByHour.get(hour) || 0
                })),
            },
            {
                key: ProblemStatus.CLOSED,
                label: 'Closed',
                color: getProblemStatusColor(ProblemStatus.CLOSED),
                data: sortedHours.map(hour => ({
                    key: formatHour(hour),
                    value: closedByHour.get(hour) || 0
                })),
            },
            {
                key: ProblemStatus.EXPIRED,
                color: getProblemStatusColor(ProblemStatus.EXPIRED),
                label: 'Expired',
                data: sortedHours.map(hour => ({
                    key: formatHour(hour),
                    value: expiredByHour.get(hour) || 0
                })),
            },
            {
                key: 'NET_OPEN',
                color: '#B1BAC3',
                label: 'Net Created',
                data: sortedHours.map(hour => ({
                    key: formatHour(hour),
                    value: netOpenByHour.get(hour) || 0
                })),
            }
        ];
    }


    /**
     * Gets the dataset given the selection.
     * @param problems to get dataset for.
     * @returns {Dataset[]} the datasets.
     */
    function getDataset(problems: Problem[]): Dataset[] {
        switch (selectedDataset) {
            case ProblemDataset.BY_IMPACT_LEVEL:
                return generateLineGraphDatasetsByImpactLevel(problems);
            case ProblemDataset.BY_PROBLEM_TYPE:
                return generateLineGraphDatasetsByType(problems);
            case ProblemDataset.BY_PROBLEM_STATUS:
                return generateLineGraphDatasetsByProblemStatus(problems);
            default:
                return [];
        }
    }

    /**
     * Get the current dataset.
     * @param statuses the problem statuses.
     * @param impactLevels the impact levels.
     * @returns {Problem[]} the filtered problems.
     */
    function filterAllRecentProblems(statuses?: ProblemStatus[], impactLevels?: ImpactLevel[]): Problem[] {
        return filteredProblems.filter(p => (statuses ? statuses.includes(p.problemStatus) : true)
            && (impactLevels !== undefined ? impactLevels.includes(p.impactLevel) : true));
    }

    /**
     * Get the current dataset.
     * @param impactLevels the impact levels.
     * @returns {Problem[]} the filtered problems.
     */
    function filterAllOpenProblems(impactLevels?: ImpactLevel[]): Problem[] {
        return allOpenProblems.filter(p =>
            (selectedProblemType === p.problemType || selectedProblemType === 'ALL')
            && (impactLevels !== undefined ? impactLevels.includes(p.impactLevel) : true)
        );
    }


    return (
        <Column
            width='100%'
            height='100%'
            alignmentHorizontal='center'
            spacingInset='none'
            spacing='none'
            backgroundColor='subdued'
        >
            {
                alertMessage && (
                    <Alert
                        type={alertMessage.type}
                        title={alertMessage.title}
                        onClose={() => {
                            setAlertMessage(undefined);
                        }}
                        data-testid='dashboard-alert'
                    >
                        {alertMessage.message}
                    </Alert>
                )
            }

            <Row
                maxWidth={MAX_WIDTH}
                spacingInset='500'
                wrap='down'
                alignmentHorizontal='justify'
                width='100%'
            >
                <PageHeader headerText={'Dashboard'} data-testid='dashboard-header'/>
                <Select
                    width={'500'}
                    label={'Problem Type'}
                    value={selectedProblemType}
                    onChange={(values) => {
                        setSelectedProblemType(values);
                    }}
                    data-testid='problem-type-select'
                >
                    <SelectOption label={'ALL'}
                                  key={'all'}
                                  value={'ALL'}/>
                    {
                        problemTypes.map(option => (
                            <SelectOption label={option}
                                          key={option}
                                          value={option}/>
                        ))
                    }
                </Select>
            </Row>
            <Row
                maxWidth={MAX_WIDTH}
                width='100%'
                wrap='down'
                spacing='500'
                widths='fill'
                alignmentVertical='stretch'
                spacingInset='none 500 500'
            >
                <Column>
                    <Box minWidth={360}>
                        <Responsive
                            query='max-width'
                            props={{
                                graphWidth: {
                                    default: 600
                                },
                                graphHeight: {
                                    default: 300
                                }
                            }}
                        >
                            {({graphWidth}) => (
                                <Column
                                    spacingInset='400 400 400 400'
                                    spacing='none'
                                    height='100%'
                                    type='outline'
                                    backgroundColor='principal'
                                >
                                    <Row alignmentHorizontal={'justify'}>
                                        <Heading level={3}>Problems</Heading>
                                        <Select
                                            width={'500'}
                                            value={selectedDataset}
                                            onChange={(value) => {
                                                setSelectedDataset(value);
                                            }}
                                            data-testid='dataset-select'
                                        >
                                            <SelectOption label={ProblemDataset.BY_PROBLEM_TYPE}
                                                          key={ProblemDataset.BY_PROBLEM_TYPE}
                                                          value={ProblemDataset.BY_PROBLEM_TYPE}/>
                                            <SelectOption label={ProblemDataset.BY_IMPACT_LEVEL}
                                                          key={ProblemDataset.BY_IMPACT_LEVEL}
                                                          value={ProblemDataset.BY_IMPACT_LEVEL}/>
                                            <SelectOption label={ProblemDataset.BY_PROBLEM_STATUS}
                                                          key={ProblemDataset.BY_PROBLEM_STATUS}
                                                          value={ProblemDataset.BY_PROBLEM_STATUS}/>
                                        </Select>
                                    </Row>
                                    {!loadingResources ? (
                                        <LegendProvider
                                            data={lineGraphDataSets}
                                            plugins={[
                                                victoryLinePlugin({theme}),
                                                victoryTooltipPlugin({showCrosshair: true}),
                                            ]}
                                            aria-label='Problem Line Chart'
                                        >
                                            {({getLineProps, voronoiContainerProps, Tooltip}) => (
                                                <Column spacing='none' width='100%'>
                                                    <VictoryChart
                                                        theme={theme}
                                                        width={graphWidth}
                                                        containerComponent={
                                                            <VictoryVoronoiContainer
                                                                labels={(datum) => {
                                                                    return datum.datum.value;
                                                                }}
                                                                {...voronoiContainerProps}
                                                            />
                                                        }
                                                        domainPadding={{x: 16, y: 20}}
                                                        minDomain={{y: 0}}
                                                    >
                                                        <VictoryAxis/>
                                                        <VictoryAxis dependentAxis/>
                                                        {lineGraphDataSets.map(({data, key}) => (
                                                            <VictoryLine
                                                                key={key}
                                                                data={data}
                                                                x='key'
                                                                y='value'
                                                                labels={(datum: DataPoint) => datum.value}
                                                                labelComponent={<Tooltip legendKey={key}/>}
                                                                {...getLineProps(key)}
                                                            />
                                                        ))}
                                                    </VictoryChart>
                                                    <Column overflowY={'scroll'}>
                                                        <Legend direction='horizontal'/>
                                                    </Column>
                                                </Column>
                                            )}
                                        </LegendProvider>
                                    ) : (
                                        <Column
                                            alignmentVertical='center'
                                            alignmentHorizontal='center'
                                            height='100%'
                                            minHeight={280}
                                        >
                                            <Loader data-testid='chart-loader'/>
                                        </Column>
                                    )}
                                </Column>
                            )}
                        </Responsive>
                    </Box>
                </Column>
                <Column>
                    <TabGroup
                        value={currentTab}
                        onChange={setCurrentTab}
                        aria-labelledby='tablist-heading'
                        data-testid='tab-group'
                    >
                        <Tab
                            value={TabOptions.LIVE}
                            data-testid='tab-overall'
                        >
                            Live
                        </Tab>
                        <Tab
                            value={TabOptions.SUMMARY}
                            disabled={true} // TODO: re-enable once we have the proper data
                            data-testid='tab-week'
                        >
                            Summary
                        </Tab>
                    </TabGroup>
                    {currentTab === TabOptions.LIVE &&
                        <Box minWidth={360}>
                            <Column spacing='300' height='100%' heights={['fit', 'fill', 'fill']}>
                                <Row spacing='300' widths='fill' wrap='down' alignmentVertical='stretch'>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='currently-open-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                Currently Open
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='currently-open-count'
                                                    >
                                                        {filterAllOpenProblems().length}
                                                    </Metric>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='currently-open-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                </Row>
                                <Row spacing='300' widths='fill' wrap='down' alignmentVertical='stretch'>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='high-impact-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                High Impact Open
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='high-impact-count'
                                                    >
                                                        {filterAllOpenProblems([ImpactLevel.CRITICAL, ImpactLevel.SEVERE]).length}
                                                    </Metric>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='high-impact-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='low-impact-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                Low Impact Open
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='low-impact-count'
                                                    >
                                                        {filterAllOpenProblems([ImpactLevel.MODERATE, ImpactLevel.MINOR, ImpactLevel.MAJOR]).length}
                                                    </Metric>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='low-impact-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                </Row>
                            </Column>
                        </Box>
                    }
                    {currentTab === TabOptions.SUMMARY &&
                        <Box minWidth={360}>
                            <Column spacing='300' height='100%' heights={['fit', 'fill', 'fill']}>
                                <Row spacing='300' widths='fill' wrap='down' alignmentVertical='stretch'>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='opened-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                Opened
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric units='problems' data-testid='opened-count'>
                                                        {filteredProblems.length}
                                                    </Metric>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='opened-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='closed-expired-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                Closed/Expired
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='closed-expired-count'
                                                    >
                                                        {filterAllRecentProblems([ProblemStatus.EXPIRED, ProblemStatus.CLOSED]).length}
                                                    </Metric>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='closed-expired-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                </Row>
                                <Row spacing='300' widths='fill' wrap='down' alignmentVertical='stretch'>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='high-impact-week-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                High Impact Opened
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='high-impact-week-count'
                                                    >
                                                        {filterAllRecentProblems(undefined, [ImpactLevel.CRITICAL, ImpactLevel.SEVERE]).length}
                                                    </Metric>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='high-impact-week-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                    <Tile
                                        spacingInset='450'
                                        data-testid='low-impact-week-tile'
                                    >
                                        <Column spacing='100' height='100%' alignmentVertical='center'>
                                            <Text color='secondary' type='h100'>
                                                Low Impact Opened
                                            </Text>
                                            {!loadingResources ? (
                                                <>
                                                    <Metric
                                                        units='problems'
                                                        data-testid='low-impact-week-count'
                                                    >
                                                        {filterAllRecentProblems(undefined,
                                                            [ImpactLevel.MODERATE, ImpactLevel.MINOR, ImpactLevel.MAJOR]).length}
                                                    </Metric>
                                                </>
                                            ) : (
                                                <Column
                                                    width='100%'
                                                    height='100%'
                                                    alignmentVertical='center'
                                                    alignmentHorizontal='center'
                                                    minHeight={80}
                                                >
                                                    <Loader size='medium' data-testid='low-impact-week-loader'/>
                                                </Column>
                                            )}
                                        </Column>
                                    </Tile>
                                </Row>
                            </Column>
                        </Box>
                    }
                </Column>
            </Row>
        </Column>
    );
};

export default Dashboard;
