/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

export type StageConfiguration = {
    cognito: {
        identityPoolId: string,
        region: string,
        userPoolId: string,
        userPoolAppClientId: string,
        userPoolDomain: string,
        redirectUrl: string
    }
    lambda: {
        ctiLambdaArn: string
    }
}
