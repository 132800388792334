/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

export type BindleLock = {
    name: string
    id: string
}

export enum LockName {
    /**
     * Super Admin lock. Has access to all functionality/tools in the ID4 Portal.
     */
    SUPER_ADMIN = 'ID4SuperAdmin'
}

/**
 * Bindle locks namespace which contains gamma/prod bindle lock names and IDs.
 */
export namespace BindleLocks {
    /**
     * Alpha, beta, and gamma bindle lock resources.
     * https://bindles-gamma.amazon.com/software_app/AR-ID4
     */
    export const GAMMA_BINDLE_LOCK_RESOURCES: BindleLock[] = [
        {
            name: LockName.SUPER_ADMIN,
            id: 'amzn1.bindle.resource.lt2gdcye5tcizuurcwga'
        }
    ];

    /**
     * Prod bindle lock resources.
     */
    export const PROD_BINDLE_LOCK_RESOURCES: BindleLock[] = [
        {
            name: LockName.SUPER_ADMIN,
            id: 'amzn1.bindle.resource.3ypmyq4ks5xeyxo37xna'
        }
    ];
}
